import React from 'react'
import Serv2 from './Serv2'

const Blog = () => {
  return (
    <>
<Serv2/>
 </>
  )
}

export default Blog