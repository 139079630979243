import React from 'react'
import {Link} from 'react-router-dom'



export default ({ portfolioLinks }) => {
    return (
        <div className="bg-light" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 mt-2 py-2 text-center">
                        <h2 style={{ color: "#1C3563" }} className="section-heading text-uppercase ">Services</h2>
                       
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-3 col-sm-5 py-2 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <img style={{borderRight:'solid 6px #026cfc' , borderBottom:'solid 6px #63c834' }  } 
                         
                             className="img-fluid"
                              src="./fac1.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4  style={{color:"#1C3563"}}>NSE AND BSE TRADING </h4>
                            <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/blog">Know More</Link>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 col-sm-5 py-2 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <img style={{borderRight:'solid 6px #026cfc' , borderBottom:'solid 6px #63c834' }  }  className="img-fluid" src="./fac2.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4  style={{color:"#1C3563"}}>CURRENCY DERIVATIVES</h4>
                            <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/blog2">Know More</Link>
                        </div>
                   </div>
                   <div className="col-6 col-md-3 col-sm-5 py-2 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <img style={{borderRight:'solid 6px #026cfc' , borderBottom:'solid 6px #63c834' }  }  className="img-fluid" src="./fac3.jpg " alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4  style={{color:"#1C3563"}}>COMMODITY SERVICES</h4>
                            <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/blog3">Know More</Link>
                        </div>
                    </div>
                   <div className="col-6 col-md-3 col-sm-5 py-2 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <img  style={{borderRight:'solid 6px #026cfc' , borderBottom:'solid 6px #63c834' }  }  className="img-fluid" src="  ./fac4.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4  style={{color:"#1C3563"}}>DEPOSITORY SERVICES</h4>
                            <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/blog4">Know More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}