import React from "react";
import logo from "./logo.svg";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./App.css";

import Slide from "./components/Slide";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
// import Portfolio from './components/MyAccount'
import About from "./components/About";
import Career from "./components/Career";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Facilities from "./components/Facilities";
import Download from "./components/Download";
import { Route, Switch } from "react-router-dom";
import Blog from "./components/Blog";
import Blog2 from "./components/Blog2";
import Blog3 from "./components/Blog3";
import Blog4 from "./components/Blog4";
import Serv1 from "./components/Serv1";
import Cards from "./components/Cards";

import { BrowserRouter as Router, Link } from "react-router-dom";
function App() {
  const portfolioLinks = [
    {
      title: "Online Trading",
      caption: "Trade online",
    },
    {
      title: "Online Depository",
      caption: "Deposit online",
    },
    {
      title: "Online Bank Office",
      caption: "Office online",
    },
    {
      title: "Account Opening",
      caption: "Online Account",
    },
    {
      title: "Contract Note",
      caption: "Contract Making",
    },
    {
      title: "Margin",
      caption: "Margin provided",
    },
  ];
  return (
    <div className="App">
      <>
        <Navbar />

        {
          <Switch>
            <Route exact path="/" component={Slide}>
              <Slide />
              <Services />
              <Facilities />
            </Route>
            <Route path="/services" component={Facilities} />
            <Route path="/blog" component={Blog} />
            <Route path="/blog2" component={Blog2} />
            <Route path="/blog3" component={Blog3} />
            <Route path="/blog4" component={Blog4} />

            <Route path="/team" component={About} />
            <Route path="/career" component={Career} />
            <Route path="/contact" component={Contact} />
            <Route path="/download" component={Download} />
            <Facilities />
            <About />
            <Contact />
          </Switch>
        }
        <Footer />
      </>
    </div>
  );
}

export default App;
