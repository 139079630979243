import React from 'react';
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'
import {Link} from 'react-router-dom'
const Cards1 = () => {
  return (
    <div className='w-full py-[3rem] px-4 bg-white'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Single} alt="/" />
              <h2 style={{color:"#1C3563"}}className='text-2xl font-bold text-center py-8'>SALIENT FEATURES:</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'>DIVERSIFY</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8 mt-8'>Indian markets have recently thrown open a new avenue for retail investors and traders to participate.</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'> For those who want to diversify their portfolios beyond shares, bonds and real estate, commodities are the best option.</p>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8'>Commodities are more than what you think they are.</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'>Almost everything you see around is made of what market considers commodity.</p>
              </div>
              <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
          <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Double} alt="/" />
              <h2 style={{color:"#1C3563"}} className='text-2xl font-bold text-center py-8'>PROFIN.</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'>TRADE</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8 mt-8'>It could be any kind of movable property, except actionable claims, money and securities. .</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Commodity trade forms the backbone of world economy..</p>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8'>Commodities offer immense potential to become a separate asset class for market-savvy investors, arbitrageurs and speculators and are easy to understand .</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Historically, pricing in commodities futures have been less volatile as compared to equity and bonds.</p>
              </div>
              <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Triple} alt="/" />
              <h2 style={{color:"#1C3563"}} className='text-2xl font-bold text-center py-8'>INTEREST RATES</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'> BENEFITS</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8 mt-8'> Like any other market, the one for commodity futures plays a valuable role in Price Discovery and Price Risk Management.</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Profin Capital Services Ltd. provides you with the perfect platform to trade in these highly valuable commodities.
</p>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8'> Profin Capital Services Ltd. strives to offer its client the best investment solutions across the country</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'> It’s our belief and tryst that each client is unique.</p>
              </div>
              <Link  className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
      </div>
    </div>
  );
};

export default Cards1;
