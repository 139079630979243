import React from 'react';
import Laptop from '../assets/laptop.jpg';

const Analytics1 = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src="./depo1.jpg" alt='/' />

      
        <div className='flex flex-col justify-center'>
          
        <p class="text-md-left">
<h2 style={{color:"#1c6338"}} >Profin Capital Services is a depository participant of both CDSL & NSDL:</h2>
<p></p>
<p></p>

<h6 style={{color:"#1C3563"}}>  One of the top Depository Participant's, we offer you the most secure, convenient and hassle-free DEMAT Accounts at the most economical rates.</h6>

<h6 style={{color:"#1C3563"}}>Profin Capital Services Ltd. offering a multitude of services under one roof also includes unparalleled Depository Services.</h6>


<h6 style={{color:"#1C3563"}}>Giving you the option to not only choose your depository services but also the opportunity of trading at one place.</h6>

<h6 style={{color:"#1C3563"}}>It is our commitment to design the best investment alternatives and products!!</h6>
<h6 style={{color:"#1C3563"}}> Profin Capital Services Ltd. is a registered member / Depository Participant of both CDSL and NSDL. </h6>
</p>
</div>
       
      </div>
    </div>
  );
};

export default Analytics1;
