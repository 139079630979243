import React from 'react'
import Serv4 from './Serv4'

const Blog4 = () => {
  return (
    <>
<Serv4/>
 </>
  )
}

export default Blog4