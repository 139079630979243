import React from 'react'



export default ({ portfolioLinks }) => {
    return (
        <section className="bg-light page-section" id="portfolio">
               <img
          className="w-full mt-1 bg-slate-900 text-gray-800 py-y px-2"
          src="./ban2.jpg" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 style={{color:"#1C3563"}} className="section-heading text-uppercase">Facilities</h2>
           
            </div>
          </div>
          <div className="row">
            
                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                    
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src="./pf1.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>ONLINE TRADING</h4>
                            <p className="text-muted">Trade online</p>
                        </div>
                    </div>


                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                   
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src="./pf2.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>ONLINE DEPOSITORY</h4>
                            <p className="text-muted">Depositories</p>
                        </div>
                    </div>
                


                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                      
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src="./pf3.jpg   " alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>ONLINE BANK OFFICE</h4>
                            <p className="text-muted">Online banking</p>
                        </div>
                    </div>
                


                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                      
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src=" ./pf4.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>ACCOUNT OPENING</h4>
                            <p className="text-muted">Open account</p>
                        </div>
                    </div>
                


                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                      
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src="./pf5.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>CONTRACT NOTE</h4>
                            <p className="text-muted">Noting contract</p>
                        </div>
                    </div>
                

                    <div className="col-md-4 col-sm-6 portfolio-item">
                        <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content">
                                    <i className="fa fa-plus fa-3x">
                                    
                                    </i>
                                </div>
                            </div>
                            <img className="img-fluid" src=" ./pf6.jpg" alt="portfolio_img" />
                        </a>
                        <div className="portfolio-caption">
                            <h4>MARGIN</h4>
                            <p className="text-muted">Available margins</p>
                        </div>
                    </div>
                
                
            
          </div>
        </div>
      </section>
    )
}