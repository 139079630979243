import React from 'react'



import Alert from 'react-bootstrap/Alert';
import Analytics1 from './Analytics1';


const Serv4 = () => {
  return (

    <section className="bg-light page-section" id="serv1">
     <div className="col-lg-12 text-center">
            <h2 style={{ color: "#1C3563" }} className="section-heading text-uppercase">DEPOSITORY SERVICES</h2>
         
         <Analytics1 />
          </div>
    

    </section>
  )
}

export default Serv4




















