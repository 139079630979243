import React from 'react';
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'
import {Link} from 'react-router-dom'
const Cards = () => {
  return (
    <div className='w-full py-[3rem] px-4 bg-white'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Single} alt="/" />
              <h2 style={{color:"#1C3563"}}className='text-2xl font-bold text-center py-8'>SALIENT FEATURES:</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'>DERIVATIVES</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8 mt-8'>Small orders can be executed upt 1 contract without any additional cost.</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'>Complete transparency of Quotes,Market price.</p>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8'>Synchronization with International market.</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'>Low Margins,Less fluctuations, Largest market.</p>
              </div>
              <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
          <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={Double} alt="/" />
              <h2 style={{color:"#1C3563"}} className='text-2xl font-bold text-center py-8'>PROFIN.</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'>TRADING</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8 mt-8'>A new trading segment which brings you Foreign Currency & Interest rate Futures Trading.</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Now available on your finger tips.</p>
                  <p style={{color:"#1C3563"}} className='py-2 border-b mx-8'>Trade in latest market offerings at your comfort And convenience.</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Initiating financial product launching tradinf facilities on currency derivative segment.</p>
              </div>
              <Link   id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={Triple} alt="/" />
              <h2 style={{color:"#1C3563"}} className='text-2xl font-bold text-center py-8'>INTEREST RATES</h2>
              <p style={{color:"#1c6338"}} className='text-center text-4xl font-bold'> BENEFITS</p>
              <div className='text-center font-medium'>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8 mt-8'>FIIS and NRIs are permitted to tarde in ETIRF.</p>
                  <p style={{color:"#1c6338"}}className='py-2 border-b mx-8'>Provides flexibility of multiple securities deliveries for one contact.</p>
                  <p style={{color:"#1C3563"}}className='py-2 border-b mx-8'>Provides flexibility of timing for securities deliveries.</p>
                  <p style={{color:"#1c6338"}} className='py-2 border-b mx-8'>Provides flexibility between square-up and delivery settlement of position.</p>
              </div>
              <Link  className="btn btn-primary btn-xl text-uppercase" to="/contact">Contact Us</Link>
          </div>
      </div>
    </div>
  );
};

export default Cards;
