import React from 'react'

const Career = () => {
    return (

  <section className=" bg-light page-section" id="career">
     
      <img
          className="w-full mt-1 bg-slate-900 text-gray-800 py-y px-2"
          src="./ban1.jpg" />
   <div className="container">
   <div className="row">
  
      <div className="col-lg-12 text-center">
        <h2 style={{color:"#1C3563"}} className="section-heading text-uppercase">Career</h2>
        
      </div>
    </div>
    <div className="row text-center">
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fa fa-circle fa-stack-2x text-primary"></i>
          <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
        </span>
        <h4 className="service-heading">Job opportunities</h4>
        <p className="text-muted">If you wish to explore career opportunities at Profin Capital Services Ltd. , Submit your resume with details for the post which you are applying.
We shall get in touch with you once we have a suitable opening matching your profile and our requirement.</p>
      </div>
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fa fa-circle fa-stack-2x text-primary"></i>
          <i className="fa fa-cube fa-stack-1x fa-inverse"></i>
        </span>
        <h4 className="service-heading">Career guidance</h4>
        <p className="text-muted">
        We are looking for dynamic minds who share our vision and mission going ahead.

We need you to help us meet our objectives that are no doubt in keeping with your goals too. if you believe that you can do justic to the work & company, we will be glad to have you with us.</p>
      </div>
      <div className="col-md-4">
        <span className="fa-stack fa-4x">
          <i className="fa fa-circle fa-stack-2x text-primary"></i>
          <i  className="fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
        </span>
        <h4 className="service-heading">Current openings</h4>
        <p className="text-muted">
        At Profin Capital Services Ltd. Limited are always looking for smart, dynamic individuals who want to excel their careers with company.So, What are you waiting for? Go ahead and mail us your resume right away. as opportunity dose not come knocking often !!!</p>
      </div>
     
    </div>
  </div>
</section>







)
}

export default Career



  
