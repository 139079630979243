import React from "react";

import download from "../assets/img/download.jpg";
const Download = () => {
  return (
    <div>
      <div className="contain">
        <img src={download} className="blurImage" />
      </div>
      <div class="centered">
        <h3 style={{ color: "#1c3563", fontSize: "50px", fontWeight: "700" }}>
          Pro Fin Capital Services Ltd.
        </h3>
        <p style={{ color: "#1c3563", fontSize: "20px", fontWeight: "500" }}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the{" "}
          <a href="http://www.google.com" target="_blank">
            industry's standard dummy text ever since the 1500s
          </a>
        </p>
        <button className="btn btn-primary"> Download Now </button>
      </div>
    </div>
  );
};

export default Download;
