import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

function CarouselFadeExample() {
  return (


    < div className='Slidu'>
        
    <Carousel fade>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src="./item2.jpg"
          alt="First slide"
        />
   
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src="./item3.jpg"
          alt="Second slide"
        />

       
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100"
          src="./item5.jpg"
          alt="Third slide"
        />

       
      </Carousel.Item>
  
    </Carousel>
    </div>
  );
}

export default CarouselFadeExample;