import React from 'react'


import Cards from './Cards';
import Alert from 'react-bootstrap/Alert';


const Serv2 = () => {
  return (

    <section className="bg-light page-section" id="serv1">
<div className="col-lg-12 text-center">
            <h2 style={{ color: "#1C3563" }} className="section-heading text-uppercase">CURRENCY DERIVATIVES</h2>
         
          </div>
    
     

<Cards />
         


    </section>
  )
}

export default Serv2




















