import React from 'react'
import Serv3 from './Serv3'

const Blog3 = () => {
  return (
    <>
<Serv3/>
 </>
  )
}

export default Blog3