import React from 'react'
import Serv1 from './Serv1'

const Blog = () => {
  return (
    <>
<Serv1/>
 </>
  )
}

export default Blog