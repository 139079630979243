import React from 'react';
import Laptop from '../assets/laptop.jpg';

const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='w-[500px] mx-auto my-4' src={Laptop} alt='/' />
   
        <p class="text-md-left">
        <div className="col-md-12 col-sm-12 ">
            <h2 style={{color:"#1c6338"}} >DERIVATIVE CONTRACTS:</h2>
       
        <h6  style={{color:"#1C3563"}}>
        Various derivative contracts constitute a significant share of all the capital market transactions in the domestic as well as global markets. In India, derivative contracts are traded on National Stock Exchange (NSE) on a huge scale and their trade is becoming increasingly prevalent even on  (BSE).  </h6>
        <h6>
        <h6 style={{color:"#1C3563"}}> Derivatives are vital for the financial system as they help to hedge against the risk and also provide you with an opportunity to profit from the anomalies in the market.</h6>
        </h6>

        <h3 style={{color:"#1c6338"}} >MARKET SEGMENT:</h3>
          <h6 style={{color:"#1C3563"}}>Our latest technological infrastructure, live market reports, in-depth analysis and tracking services enable you to opt for appropriate and highly profitable derivative strategies such as Bull Spread, Bear Spread, Cover Call Writing, Hedging Strategies, etc that are specific to your individual portfolio. </h6>
          <h6 style={{color:"#1C3563"}}>
        Our Derivative Market segment has a composite understanding of the equity and derivatives market that is vividly reflected in our unique and effective Trading / Hedging / Arbitrage strategies.
        </h6>
      
        
      </div>
      </p>

        <div className='flex flex-col justify-center'>
          
  <p class="text-md-left">
<h2 style={{color:"#1c6338"}} >Profin Capital Services Ltd can enhance your investing experience with:</h2>

<h6 style={{color:"#1C3563"}}> Daily Market Analysis, Advisory reports & Special Situation Research Reports.</h6>
<h6 style={{color:"#1C3563"}}> Futures & Options / Derivatives trading for those with a higher risk appetite. </h6>

<h6 style={{color:"#1C3563"}}> Excellent trade execution capabilities on BSE, NSE, MCX and NCDEX.</h6>
<h6 style={{color:"#1C3563"}}>Online Depository Services with Auto Pay-in facility.</h6>
<h6 style={{color:"#1C3563"}}>  Online real-time back office, available 24/7.</h6>
<h6 style={{color:"#1C3563"}}> Arbitrage trading strategies. </h6>
<h6 style={{color:"#1C3563"}}> Seamless transaction flow.</h6>

</p>
        </div>
   
      </div>
    
    </div>

  );
};

export default Analytics;
