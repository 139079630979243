import React from 'react'
import {
  CloudUploadIcon,
  DatabaseIcon,
  PaperAirplaneIcon,
  ServerIcon,
} from '@heroicons/react/solid'
import {Carousel} from 'react-bootstrap'

import Analytics from './Analytics';

import Alert from 'react-bootstrap/Alert';


const Serv1 = () => {
  return (
    
<section className="bg-light page-section" id="serv1">
<div className="col-lg-12 text-center">
            <h2 style={{ color: "#1C3563" }} className="section-heading text-uppercase">NSE/BSE TRADING</h2>
         
          </div>
            
        

         <Analytics />
              
             
         
          
   

    </section>
  )
}

export default Serv1