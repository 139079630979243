import React from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {BrowserRouter as Router,Link} from 'react-router-dom';

const Navbar = () => {
  return (

    <nav className="navbar navbar-expand-lg fixed-top bg-white" id="mainNav">
      <div className="container">

        <img src="logo192.png" className="App-logo" alt="logo"></img>
       
        <NavDropdown button  className="navbar-toggler navbar-toggler-right  " type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" >

      
          
              <NavDropdown.Item>
              <Link to="/">Home</Link></NavDropdown.Item>


              <NavDropdown.Item>
              <Link to="/portfolio">Facilities</Link></NavDropdown.Item>
             


              <NavDropdown.Item>
              <Link to="/career">Career</Link></NavDropdown.Item>
              
              <NavDropdown.Item>
              <Link to="/team">    About</Link></NavDropdown.Item>

              <NavDropdown.Item>
              <Link to="/contact"> Contact</Link></NavDropdown.Item>

              <NavDropdown.Item>
              <Link to="/download"> Download</Link></NavDropdown.Item>
 
            </NavDropdown>

      <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/">Home</Link>
            </li>


            
            <NavDropdown title="Services" className="nav-item nav-link">
              <NavDropdown.Item>
              <Link to="/blog">NSE/BSE Trading</Link></NavDropdown.Item>


              <NavDropdown.Item>
              <Link to="/blog2">Currency Derivatives</Link></NavDropdown.Item>
             


              <NavDropdown.Item>
              <Link to="/blog3">Commodities</Link></NavDropdown.Item>
              
              <NavDropdown.Item>
              <Link to="/blog4">      Depository Services</Link></NavDropdown.Item>




              
            </NavDropdown>

            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/portfolio">Facilities</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/career">career</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/team">About</Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/contact">Contact</Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to="/download">Download</Link>
            </li>
          </ul>
        </div>
      </div>

    </nav>


  )
}

export default Navbar



