import React from 'react'
const About = () => {
    return (

  <section className="bg-light page-section" id="team">
       <img
          className="w-full mt-1 bg-slate-900 text-gray-800 py-y px-2"
          src="./ban3.jpg" />
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <h2 style={{color:"#1C3563"}}className="section-heading text-uppercase">About</h2>
    
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="https://images.unsplash.com/photo-1481277542470-605612bd2d61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fHdlYnNpdGUlMjBiYWNrZ3JvdW5kfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt=""/>
          <h4 style={{color:"#1C3563"}}>DISCLAIMER</h4>
          <p className="text-muted"> We provide you convenience of online trading at your own place.</p>
        
        </div>
      </div>
      <div className="col-sm-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="https://images.unsplash.com/photo-1517135399940-2855f5be7c4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHdlYnNpdGUlMjBncmVlbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60" alt=""/>
          <h4 style={{color:"#1C3563"}}>VISION</h4>
          <p className="text-muted">To help people around us to invest and earn better.</p>
         
        </div>
      </div>
      <div className="col-sm-4">
        <div className="team-member">
          <img className="mx-auto rounded-circle" src="https://images.unsplash.com/photo-1494319827402-c4b839aed26b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHdlYnNpdGUlMjBiYWNrZ3JvdW5kJTIwZ3JlZW58ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60" alt=""/>
          <h4 style={{color:"#1C3563"}}>MISSION</h4>
          <p className="text-muted">Profitable growth through superior customer service.</p>
         
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-8 mx-auto text-center">
        <p className="large text-muted">
Our Team consists of people who pursue a customer-centric approach by exceeding customer expectations through efficient and timely services besides persistently upgrading our quality standards, retaining the spirit of teamwork, integrity, transparency and fairness.</p>
      </div>
    </div>
  </div>
</section>

    )
}

export default About